<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <div @click="$router.replace('/')" class="icon">
              <ModalCloseIcon :dark="true" />
            </div>
          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div class="content">
              <div class="logo">
                <img src="../assets/logo-n.svg" alt="Nvoko Logo" />
              </div>
              <h4>
                Enter the verification code sent to your email (
                <b>
                  <i>{{ user.email }}</i>
                </b>
                ) :
              </h4>
              <div class="code-container">
                <div
                  v-for="i in 6"
                  :key="i"
                  contenteditable
                  class="code-digit"
                  :id="`code${i - 1}`"
                  @input="e => onCodeInput(e, i - 1)"
                  @keydown="e => checkDeletePrev(e, i - 1)"
                >
                  {{ code[i - 1] }}
                </div>
              </div>
              <button
                @click="submit()"
                class="primary"
                :disabled="!enableSubmitButton || submitting"
              >
                {{ submitting ? "Loading..." : "Continue" }}
              </button>
              <span class="resend" v-if="resending">Resending...</span>
              <span
                v-else
                class="resend"
                @click="countDown ? 0 : resend()"
                :class="{ 'resend-active': !countDown }"
              >
                Didn't receive the code? Request another one
                {{ countDown ? `(${countDown} secs)` : "" }}
              </span>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import RESEND_VERIFICATION_CODE from "@/api/mutations/RESEND_VERIFICATION_CODE.gql";
import COMPLETE_VERIFICATION from "@/api/mutations/COMPLETE_VERIFICATION.gql";

export default {
  name: "SignupVerification",
  components: { ModalCloseIcon },
  data() {
    return {
      valid: false,
      code: [],
      countDown: 59,
      resending: false,
      submitting: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["account/getUser"];
    },
    enableSubmitButton() {
      return this.code.join("").length === 6;
    }
  },
  mounted() {
    document.querySelector("#code0").focus();
    this.countDownTimer();
  },
  methods: {
    onCodeInput(e, i) {
      const value = e.target.innerText;
      if (!i && value.length === 6) {
        e.target.innerText = "";
        return this.pasteCode(value);
      }

      const valid = value.match(/\b[0-9]\b/);
      let diff;
      if (!valid && value) {
        e.target.innerText = this.code[i] || "";
        diff = 0;
      } else {
        this.$set(this.code, i, value);
        e.target.innerText = this.code[i];
        if (i === 5) diff = 0;
        else if (i < 5 && value) diff = 1;
        else return;
      }

      const input = document.querySelector(`#code${i + diff}`);
      input.focus();
      if (input.childNodes.length) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(input.childNodes[0], 1);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    },
    checkDeletePrev(e, i) {
      if (i && !this.code[i] && !e.target.innerText && e.keyCode === 8) {
        const input = document.querySelector(`#code${i - 1}`);
        input.innerText = "";
        this.code[i - 1] = "";
        this.$set(this.code, i - 1, "");
        input.focus();
      }
    },
    pasteCode(value) {
      if (!value.match(/[0-9]{6,6}/)) return;
      for (let i in [0, 1, 2, 3, 4, 5]) {
        const current = value[i];
        const input = document.querySelector(`#code${i}`);
        input.innerText = current;
        this.$set(this.code, i, current);
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(input.childNodes[0], 1);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async submit() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        const result = await this.$apollo.mutate({
          mutation: COMPLETE_VERIFICATION,
          variables: { code: this.code.join("") }
        });
        const { complete_verification: update } = result.data;
        this.$token = update.token;
        this.$store.commit("account/authSuccess", update);
        this.$router.push({ name: "Onboarding" });
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.submitting = false;
      }
    },
    async resend() {
      if (this.resending) return;
      try {
        this.resending = true;
        const result = await this.$apollo.mutate({
          mutation: RESEND_VERIFICATION_CODE
        });
        const { resend_verification_code } = result.data;
        if (resend_verification_code.success) this.countDown = 59;
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.resending = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 32px;
}

.modal-body {
  width: 100%;
  height: calc(100vh - 164px);
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .logo {
      margin-bottom: 32px;
    }
    h2 {
      color: $white;
      margin-bottom: 16px;
    }
    h4 {
      color: $white;
      font-weight: 300;
      margin-bottom: 3rem;
    }
    .code-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
      .code-digit {
        width: 60px;
        height: 60px;
        font-size: 35px;
        padding-bottom: 10px;
        color: $white;
        border: 1px solid rgb(110, 110, 110);
        border-radius: 6px;
        text-align: center;
        background: none;
        outline: none;
        &:not(:first-child) {
          margin-left: 25px;
        }
      }
    }
    button {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.22px;
      padding: 10px 30px 12px;
    }
    .resend {
      color: #adadad;
      margin-top: 1.5rem;
    }
    .resend-active {
      color: $accent;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
